import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue');
const GridSystem = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue');
const Panels = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue');
const SweetAlert = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue');
const Notifications = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue');
const Icons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
const Typography = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import Widgets from 'src/pages/Widgets.vue';

// Forms pages
const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Forms/Wizard.vue');

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/GoogleMaps.vue');
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/FullScreenMap.vue');
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Mail = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Mail.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const RTL = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');
const Main = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Main.vue');

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');
const ExtendedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue');
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue');

const ReceiveList = () =>
  import('@/pages/Receive/ReceiveListsTables');
const ReceiveCreate = () =>
  import('@/pages/Receive/ReceiveCreate')
const ReceiveEdit = () =>
  import('@/pages/Receive/ReceiveEdit')
const ReceivePrint = () =>
  import('@/pages/Receive/ReceiveBarcode')
const ReceivePrint2 = () =>
  import('@/pages/Receive/ReceiveBarcode2')
const ReceiveView = () =>
  import('@/pages/Receive/ReceiveView')




const OrderList = () =>
  import('@/pages/Order/OrderListsTables')
const OrderListAdmin = () =>
  import('@/pages/Order/OrderListsAdminTables')
const OrderCreate = () =>
  import('@/pages/Order/OrderCreate')
const OrderDetail = () =>
  import('@/pages/Order/OrderDetail')
const OrderCarDetail = () =>
  import('@/pages/Order/OrderCarDetail')
const OrderDetailView = () =>
  import('@/pages/Order/OrderDetailView')
const OrderPickingList = () =>
  import('@/pages/Picking/OrderPickingListsTables')
const OrderPickingCarList = () =>
  import('@/pages/Picking/OrderPickingCarListsTables')
const OrderPickingConfirmList = () =>
  import('@/pages/Picking/OrderPickingConfirmListsTables')
  const OrderPickingCompleteLists = () =>
  import('@/pages/Picking/OrderPickingCompleteListsTables')
const PickingList = () =>
  import('@/pages/Picking/PickingListsTables')
const PickingHistoryLists = () =>
  import('@/pages/Picking/PickingHistoryListTables')
const PickingHistoryDetailLists = () =>
  import('@/pages/Picking/PickingHistoryDetailListTables')

const ScanOrderPickingCarList = () =>
  import('@/pages/Picking/ScanOrderPickingCarListsTables')



// HISTORY TRANFER
const HistoryTranferList = () =>
  import('@/pages/HistoryTranfer/HistoryTranferListTable')


// STOCK
const StockList = () =>
  import('@/pages/Stock/StockListsTables')
const StockDetail = () =>
  import('@/pages/Stock/StockDetail')


// INVOICE ADMIN
const InvoiceAdminList = () =>
  import('@/pages/InvoiceAdmin/InvoiceAdminListsTables')


// INVOICE
const InvoiceList = () =>
  import('@/pages/Invoice/InvoiceListsTables')

const TransactionListAdmin = () =>
  import('@/pages/Transaction/TransactionListsAdminTables')
const TransactionList = () =>
  import('@/pages/Transaction/TransactionListsTables')

// REPORT
const ReportProductList = () =>
  import('@/pages/Report/ReportProductList')
const ReportZipCodeList = () =>
  import('@/pages/Report/ReportZipCodeList')
  const ReportBoxList = () =>
  import('@/pages/Report/ReportBoxList')



// REPORT USER
const ReportUserProductList = () =>
  import('@/pages/ReportUser/ReportUserProductList')
const ReportUserZipCodeList = () =>
  import('@/pages/ReportUser/ReportUserZipCodeList')


//SUMMARY
const TransactionSummaryAdminList = () =>
  import('@/pages/TransactionSummary/TransactionSummaryAdminList')
const TransactionSummaryList = () =>
  import('@/pages/TransactionSummary/TransactionSummaryList')

// BOX
const BoxList = () =>
  import('@/pages/Box/BoxListsTables')
const BoxCreate = () =>
  import('@/pages/Box/BoxCreate')
const BoxEdit = () =>
  import('@/pages/Box/BoxEdit')
const BoxBuy = () =>
  import('@/pages/Box/BoxBuy')



// STORAGE
const StorageList = () =>
  import('@/pages/Storage/StorageListsTables')
const StorageCreate = () =>
  import('@/pages/Storage/StorageCreate')

// PRODUCT
const ProductList = () =>
  import('@/pages/Product/ProductListTable')
const ProductCreate = () =>
  import('@/pages/Product/ProductCreate')
const ProductEdit = () =>
  import('@/pages/Product/ProductEdit')


// UPDATE DELIVERY
const UpdateDeliveryList = () =>
  import('@/pages/UpdateDelivery/UpdateDeliveryListsTables')


// GOODS-OUT
const GoodsOutList = () =>
  import('@/pages/GoodsOut/GoodsOutListTable')
const GoodsOutCreate = () =>
  import('@/pages/GoodsOut/GoodsOutCreate')
const GoodsOutView = () =>
  import('@/pages/GoodsOut/GoodsOutView')


// USERS
const WaitingApprove = () =>
  import('@/pages/Pages/WaitingApprove')
const UserCreate = () =>
  import('@/pages/User/UserCreate')
const UserEdit = () =>
  import('@/pages/User/UserEdit')
const UserList = () =>
  import('@/pages/User/UserListsTables')
const UserDetail = () =>
  import('@/pages/User/UserApproveProfile')
const UserBarCode = () =>
  import('@/pages/User/UserBarcode')
const UserChangePassword = () =>
  import('@/pages/User/UserChangePassword')



//Staff
const StaffCreate = () =>
  import('@/pages/Staff/StaffCreate')
const StaffEdit = () =>
  import('@/pages/Staff/StaffEdit')
const StaffList = () =>
  import('@/pages/Staff/StaffListsTables')
const StaffChangePassword = () =>
  import('@/pages/Staff/StaffChangePassword')




// CHANGE PASSWORD
const ChangePasswordIndex = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/ChangePassword/ChangePasswordIndex.vue');

// EDIT PROFILE
const EditProfileIndex = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/EditProfile/EditProfileIndex.vue');


let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      components: {default: Buttons}
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      components: {default: GridSystem}
    },
    {
      path: 'panels',
      name: 'Panels',
      components: {default: Panels}
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      components: {default: SweetAlert}
    },
    {
      path: 'notifications',
      name: 'Notifications',
      components: {default: Notifications}
    },
    {
      path: 'icons',
      name: 'Icons',
      components: {default: Icons}
    },
    {
      path: 'typography',
      name: 'Typography',
      components: {default: Typography}
    }
  ]
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  name: 'Forms',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      components: {default: RegularForms}
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      components: {default: ExtendedForms}
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      components: {default: ValidationForms}
    },
    {
      path: 'wizard',
      name: 'Wizard',
      components: {default: Wizard}
    }
  ]
};

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  name: 'Tables',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      components: {default: RegularTables}
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      components: {default: ExtendedTables}
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      components: {default: PaginatedTables}
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      components: {default: GoogleMaps}
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      meta: {
        hideContent: true,
        hideFooter: true
      },
      components: {default: FullScreenMap}
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      components: {default: VectorMaps}
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      components: {default: User}
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      components: {default: TimeLine}
    },
    {
      path: 'rtl',
      name: 'RTL Page',
      components: {default: RTL}
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/mail',
      name: 'Mail',
      component: Mail
    },


    {
      path: '/main',
      name: 'Main',
      component: Main
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock2',
      name: 'Lock',
      component: Lock
    }
  ]
};
let receiveMenu = {
  path: '/receive',
  component: DashboardLayout,
  redirect: '/receive/list',
  name: 'Receive',
  children: [
    {
      path: 'list',
      name: 'รายการรับสินค้าเข้าคลัง',
      components: {default: ReceiveList}
    },
    {
      path: 'create',
      name: 'เพิ่มรายการรับสินค้าเข้าคลัง',
      components: {default: ReceiveCreate}
    },
    {
      path: 'view/:id',
      name: 'รายการรับสินค้าเข้าคลัง',
      components: {default: ReceiveView}
    },
    {
      path: 'edit/:id',
      name: 'Received Edit',
      components: {default: ReceiveEdit}
    },
    {
      path: 'print/:recNo',
      name: 'ปริ้นรายการรับสินค้าเข้าคลัง',
      components: {default: ReceivePrint}
    },
    {
      path: 'print2/:recNo&:barCode',
      name: 'ปริ้นรายการรับสินค้าเข้าคลัง',
      components: {default: ReceivePrint2}
    }
  ]
};





let goodsOutMenu = {
  path: '/goods-out',
  component: DashboardLayout,
  redirect: '/goods-out/list',
  name: 'Goods Out',
  children: [
    {
      path: 'list',
      name: 'รายการนำสินค้าออกจากคลัง',
      components: {default: GoodsOutList}
    },
    {
      path: 'create',
      name: 'เพิ่มรายการนำสินค้าออกจากคลัง',
      components: {default: GoodsOutCreate}
    },
    {
      path: 'print/:recNo',
      name: 'ปริ้นรายการนำสินค้าออกจากคลัง',
      components: {default: ReceivePrint}
    },
    {
      path: 'view/:id',
      name: 'รายการนำสินค้าออกจากคลัง',
      components: {default: GoodsOutView}
    },


  ]
};







let orderMenu = {
  path: '/order',
  component: DashboardLayout,
  redirect: '/order/list',
  name: 'Order',
  children: [
    {
      path: 'adminOrderList',
      name: 'รายการรอดำเนินการ',
      components: {default: OrderListAdmin}
    },
    {
      path: 'list',
      name: 'รายการใบสั่งซื้อ',
      components: {default: OrderList}
    },
    {
      path: 'create',
      name: 'เพิ่มรายการใบสั่งซื้อ',
      components: {default: OrderCreate}
    },
    {
      path: 'detail/:orderNo',
      name: 'Order View',
      components: {default: OrderDetailView}
    },
    {
      path: 'pick-history',
      name: 'ประวัติการหยิบของ',
      components: {default: PickingHistoryLists}
    },

    {
      path: 'pick-history/detail/:orderTime',
      name: 'รายละเอียดประวัติการหยิบ',
      components: {default: PickingHistoryDetailLists}
    },


  ]
}

let productMenu = {
  path: '/product',
  component: DashboardLayout,
  redirect: '/product/list',
  name: 'Product',
  children: [
    {
      path: 'list',
      name: 'รายการสินค้า',
      components: {default: ProductList}
    },

    {
      path: 'create',
      name: 'เพิ่มรายการสินค้า',
      components: {default: ProductCreate}
    },

    {
      path: 'edit/:id',
      name: 'แก้ไขรายการสินค้า',
      components: {default: ProductEdit}
    },


  ]
}


let updateDeliveryMenu = {
  path: '/update-delivery',
  component: DashboardLayout,
  redirect: '/update-delivery/list',
  name: 'Update Delivery',
  children: [
    {
      path: 'list',
      name: 'อัพเดทการส่งสินค้า',
      components: {default: UpdateDeliveryList}
    },

  ]
}

let stockMenu = {
  path: '/stock',
  component: DashboardLayout,
  redirect: '/stock/list',
  name: 'Stock',
  children: [
    {
      path: 'list',
      name: 'รายการสต๊อกสินค้า',
      components: {default: StockList}
    },
    {
      path: 'detail/:id',
      name: 'รายละเอียดสินค้า',
      components: {default: StockDetail}
    },

  ]
}


let invoiceAdminMenu = {
  path: '/invoice-admin',
  component: DashboardLayout,
  redirect: '/invoice-admin/list',
  name: 'Invoice Admin',
  children: [
    {
      path: 'list',
      name: 'รายการใบแจ้งหนี้สำหรับแอดมิน',
      components: {default: InvoiceAdminList}
    },


  ]
}

let invoiceMenu = {
  path: '/invoice',
  component: DashboardLayout,
  redirect: '/invoice/list',
  name: 'Invoice',
  children: [
    {
      path: 'list',
      name: 'รายการใบแจ้งหนี้',
      components: {default: InvoiceList}
    },
  ]
}

let transactionMenu = {
  path: '/transaction',
  component: DashboardLayout,
  redirect: '/transaction/list',
  name: 'Transaction',
  children: [
    {
      path: 'list',
      name: 'รายการดำเนินการ',
      components: {default: TransactionList}
    },
    {
      path: 'list-admin',
      name: 'Transaction',
      components: {default: TransactionListAdmin}
    }
  ]
}


let boxMenu = {
  path: '/box',
  component: DashboardLayout,
  redirect: '/box/list',
  name: 'Box',
  children: [
    {
      path: 'list',
      name: 'รายการกล่อง',
      components: {default: BoxList}
    },
    {
      path: 'create',
      name: 'เพิ่มรายการกล่อง',
      components: {default: BoxCreate}
    },
    {
      path: 'edit/:id',
      name: 'แก้ไขกล่อง',
      components: {default: BoxEdit}
    },
    {
      path: 'buy/:id',
      name: 'ซื้อกล่อง',
      components: {default: BoxBuy}
    },


  ]
}


let storageMenu = {
  path: '/storage',
  component: DashboardLayout,
  redirect: '/storage/list',
  name: 'Storage',
  children: [
    {
      path: 'list',
      name: 'รายการพื้นที่จัดเก็บ',
      components: {default: StorageList}
    },
    {
      path: 'create',
      name: 'เพิ่มรายการพื้นที่จัดเก็บ',
      components: {default: StorageCreate}
    },


  ]
}


let reportProductMenu = {
  path: '/report-product',
  component: DashboardLayout,
  redirect: '/report-product/list',
  name: 'Report Product',
  children: [
    {
      path: 'list',
      name: 'รายงานสินค้า',
      components: {default: ReportProductList}
    },

  ]
}


let reportZipCodeMenu = {
  path: '/report-zipcode',
  component: DashboardLayout,
  redirect: '/report-zipcode/list',
  name: 'Report Zipcode',
  children: [
    {
      path: 'list',
      name: 'รายงานไปรษณีย์',
      components: {default: ReportZipCodeList}
    },

  ]
}

let reportฺBoxMenu = {
  path: '/report-box',
  component: DashboardLayout,
  redirect: '/report-box/list',
  name: 'Report Zipcode',
  children: [
    {
      path: 'list',
      name: 'รายงานกล่อง',
      components: {default: ReportBoxList}
    },

  ]
}






let reportUserProductMenu = {
  path: '/report-user-product',
  component: DashboardLayout,
  redirect: '/report-user-product/list',
  name: 'Report Product',
  children: [
    {
      path: 'list',
      name: 'รายงานสินค้า',
      components: {default: ReportUserProductList}
    },

  ]
}


let reportUserZipCodeMenu = {
  path: '/report-user-zipcode',
  component: DashboardLayout,
  redirect: '/report-usser-zipcode/list',
  name: 'Report Zipcode',
  children: [
    {
      path: 'list',
      name: 'รายงานไปรษณีย์',
      components: {default: ReportUserZipCodeList}
    },

  ]
}



let changePasswordMenu = {
  path: '/change-password',
  component: DashboardLayout,
  redirect: '/change-password',
  name: 'ChangePassword',
  children: [
    {
      path: '',
      name: 'เปลี่ยนรหัสผ่าน',
      components: {default: ChangePasswordIndex}
    },

  ]
}

let editProfileMenu = {
  path: '/edit-profile',
  component: DashboardLayout,
  redirect: '/edit-profile',
  name: 'EditProfile',
  children: [
    {
      path: '',
      name: 'แก้ไขข้อมูลส่วนตัว',
      components: {default: EditProfileIndex}
    },

  ]
}






let pickingMenu = {
  path: '/picking',
  component: DashboardLayout,
  redirect: '/picking/list',
  name: 'Picking',
  children: [
    {
      path: 'list',
      name: 'รายการรอบรรจุ',
      components: {default: OrderPickingList}
    },
    {
      path: 'list-confirm',
      name: 'Picking Confirm List',
      components: {default: OrderPickingConfirmList}
    },
    {
      path: 'list-complete',
      name: 'Picking Complete List',
      components: {default: OrderPickingCompleteLists}
    },
    {
      path: ':customer',
      name: 'Pick List',
      components: {default: PickingList}
    },
    {
      path: 'detail/:orderNo',
      name: 'Order Detail',
      components: {default: OrderDetail}
    },




  ]
}


let pickingCarMenu = {
  path: '/picking-car',
  component: DashboardLayout,
  redirect: '/picking-car/list',
  name: 'Picking car',
  children: [
    {
      path: 'list',
      name: 'รายการรอขึ้นรถ',
      components: {default: OrderPickingCarList}
    },
    {
      path: 'detail/:orderNo',
      name: 'รายละเอียดรายการรอขึ้นรถ',
      components: {default: OrderCarDetail}
    }

  ]
}


let scanPickingCarMenu = {
  path: '/scan-picking-car',
  component: DashboardLayout,
  redirect: '/scan-picking-car/list',
  name: 'Scan Picking Car',
  children: [
    {
      path: 'list',
      name: 'สแกนรายการขึ้นรถ',
      components: {default: ScanOrderPickingCarList}
    },


  ]
}

let historyTranferMenu = {
  path: '/history-tranfer',
  component: DashboardLayout,
  redirect: '/history-tranfer/list',
  name: 'History Tranfer',
  children: [
    {
      path: 'list',
      name: 'ประวัตินำสินค้าเข้า-ออก',
      components: {default: HistoryTranferList}
    },


  ]
}

let transactionSummaryAdminMenu = {
  path: '/transaction-summary-admin',
  component: DashboardLayout,
  redirect: '/transaction-summary-admin/list',
  name: 'Transaction Summary',
  children: [
    {
      path: 'list',
      name: 'ค่าใช้จ่าย',
      components: {default: TransactionSummaryAdminList}
    },


  ]
}

let transactionSummaryMenu = {
  path: '/transaction-summary',
  component: DashboardLayout,
  redirect: '/transaction-summary/list',
  name: 'Transaction Summary',
  children: [
    {
      path: 'list',
      name: 'ค่าใช้จ่าย',
      components: {default: TransactionSummaryList}
    },


  ]
}


let userMenu = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/list',
  name: 'Users',
  children: [
    {
      path: 'list',
      name: 'สมาชิก',
      components: {default: UserList}
    },
    {
      path: 'create',
      name: 'เพิ่มสมาชิก',
      components: {default: UserCreate}
    },
    {
      path: 'edit/:id',
      name: 'แก้ไขสมาชิก',
      components: {default: UserEdit}
    },

    {
      path: 'changePassword/:id',
      name: 'เปลี่ยนรหัสผ่าน',
      components: {default: UserChangePassword}
    },

    {
      path: 'detail/:id',
      name: 'User Detail',
      components: {default: UserDetail}
    },
    {
      path: 'print/:barcode',
      name: 'User Barcode',
      components: {default: UserBarCode}
    }
  ]
}




let staffMenu = {
  path: '/staff',
  component: DashboardLayout,
  redirect: '/staff/list',
  name: 'Staff',
  children: [
    {
      path: 'list',
      name: 'เจ้าหน้าที่',
      components: {default: StaffList}
    },
    {
      path: 'create',
      name: 'เพิ่มเจ้าหน้าที่',
      components: {default: StaffCreate}
    },
    {
      path: 'changePassword/:id',
      name: 'เปลี่ยนรหัสผ่าน',
      components: {default: StaffChangePassword}
    },
    {
      path: 'edit/:id',
      name: 'แก้ไขเจ้าหน้าที่',
      components: {default: StaffEdit}
    },
  ]
}


const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  {
    path: '/waiting',
    component: AuthLayout,
    name: 'UserWaiting',
    components: {default: WaitingApprove}
  },
  userMenu,
  receiveMenu,
  orderMenu,
  stockMenu,
  boxMenu,
  storageMenu,
  transactionMenu,
  invoiceAdminMenu,
  invoiceMenu,
  productMenu,
  changePasswordMenu,
  pickingMenu,
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  editProfileMenu,
  updateDeliveryMenu,
  pickingCarMenu,
  scanPickingCarMenu,
  goodsOutMenu,
  historyTranferMenu,
  reportProductMenu,
  reportZipCodeMenu,
  reportUserProductMenu,
  reportUserZipCodeMenu,
  reportฺBoxMenu,
  authPages,
  staffMenu,
  transactionSummaryAdminMenu,
  transactionSummaryMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'หน้าแรก',
        components: {default: Dashboard}
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: {default: Calendar}
      },
      {
        path: 'charts',
        name: 'Charts',
        components: {default: Charts}
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: {default: Widgets}
      }
    ]
  },
  {path: '*', redirect: '/'}
];

export default routes;

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

// configure router
export const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register','/lock','/waiting' , '/main' , '/mail'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('auth');
  const users = localStorage.getItem('users');
  const userData = JSON.parse(users)
  if (authRequired && !loggedIn) {
    return next('/main');
  }
  if(users) {
    if (userData.status === "registered" && authRequired) {
      return next('/waiting');
    }
  }
  next();
})

export default router;

<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template  slot="links">
        <sidebar-item
          :link="{
            name: 'หน้าแรก',
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item>

        <!-- user -->

        <sidebar-item
          v-if="MenuUser"
          :link="{ name: 'สินค้า', icon: 'tim-icons icon-basket-simple', path: '/stock' }">
        </sidebar-item>
         <sidebar-item
          v-if="MenuUser"
          :link="{ name: 'ใบสั่งซื้อ', icon: 'tim-icons icon-app', path: '/order' }">
        </sidebar-item>

          <label  v-if="MenuUser" style="color:white; font-size:12px;font-weight: bold;margin: 20px">รายงาน</label>
          <sidebar-item
            v-if="MenuUser"
            :link="{ name: 'รายงานสินค้า', icon: 'tim-icons icon-paper', path: '/report-user-product/list'}">
          </sidebar-item>
          <sidebar-item
            v-if="MenuUser"
            :link="{ name: 'รายงานไปรษณีย์', icon: 'tim-icons icon-paper', path: '/report-user-zipcode/list'}">
          </sidebar-item>

         <sidebar-item
          v-if="MenuUser"
          :link="{ name: 'ค่าใช้จ่าย', icon: 'tim-icons icon-paper', path: '/transaction-summary' }">
        </sidebar-item>

        <!-- superadmin -->
         <label v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">จัดการสินค้าคงคลัง</label>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'รับสินค้าเข้าคลัง', icon: 'tim-icons icon-notes', path: '/receive' }">
        </sidebar-item>
         <sidebar-item
          v-if="superadmin"
          :link="{ name: 'นำสินค้าออกจากคลัง', icon: 'tim-icons icon-notes', path: '/goods-out' }">
        </sidebar-item>
         <label v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">กระบวนการบรรจุสินค้า</label>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'รายการรอดำเนินการ', icon: 'tim-icons icon-paper', path: '/order/adminOrderList' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'รายการรอบรรจุ', icon: 'tim-icons icon-app', path: '/picking/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'รายการรอขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'สแกนรายการขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/scan-picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'รายการขึ้นรถแล้ว', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-complete' }">
        </sidebar-item>
         <sidebar-item
          v-if="superadmin"
          :link="{ name: 'อัพเดทการส่งสินค้า', icon: 'tim-icons icon-pencil', path: '/update-delivery' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'ประวัติการสั่งบรรจุ', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-confirm' }">
        </sidebar-item>
         <sidebar-item
          v-if="superadmin"
          :link="{ name: 'ประวัติการหยิบของ', icon: 'tim-icons icon-app', path: '/order/pick-history' }">
        </sidebar-item>
        <label v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">ข้อมูลหลัก</label>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'สินค้า', icon: 'tim-icons icon-basket-simple' , path: '/product' }">
        </sidebar-item>
        <sidebar-item
          v-if="superadmin"
          :link="{ name: 'สมาชิก', icon: 'tim-icons icon-single-02', path: '/user' }">
        </sidebar-item>
         <sidebar-item
            v-if="superadmin"
            :link="{ name: 'กล่อง', icon: 'tim-icons icon-app', path: '/box/list'}">
          </sidebar-item>


        <label  v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">รายงาน</label>
          <sidebar-item
            v-if="superadmin"
            :link="{ name: 'รายงานสินค้า', icon: 'tim-icons icon-paper', path: '/report-product/list'}">
          </sidebar-item>
          <sidebar-item
            v-if="superadmin"
            :link="{ name: 'รายงานไปรษณีย์', icon: 'tim-icons icon-paper', path: '/report-zipcode/list'}">
          </sidebar-item>
           <sidebar-item
            v-if="superadmin"
            :link="{ name: 'รายงานกล่อง', icon: 'tim-icons icon-paper', path: '/report-box/list'}">
          </sidebar-item>
          <sidebar-item
          v-if="superadmin"
          :link="{ name: 'ค่าใช้จ่าย', icon: 'tim-icons icon-paper', path: '/transaction-summary-admin' }">
        </sidebar-item>



        <label v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">เจ้าหน้าที่</label>
         <sidebar-item
          v-if="superadmin"
          :link="{ name: 'เจ้าหน้าที่', icon: 'tim-icons icon-single-02', path: '/staff' }">
        </sidebar-item>

        
        <div v-if="superadmin">
          <a href="http://178.128.100.105/login" target="_blank" v-if="superadmin" style="color:white; font-size:12px;font-weight: bold;margin: 20px">WMS</a>
        </div>
        
        



        <div   v-if="superadmin" style="height: 120px;">
        </div>

        <!-- admin_order -->
        <label v-if="admin_order" style="color:white; font-size:12px;font-weight: bold;margin: 20px">กระบวนการบรรจุสินค้า</label>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'รายการรอดำเนินการ', icon: 'tim-icons icon-paper', path: '/order/adminOrderList' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'รายการรอบรรจุ', icon: 'tim-icons icon-app', path: '/picking/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'รายการรอขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'สแกนรายการขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/scan-picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'รายการขึ้นรถแล้ว', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-complete' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_order"
          :link="{ name: 'อัพเดทการส่งสินค้า', icon: 'tim-icons icon-pencil', path: '/update-delivery' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_order"
          :link="{ name: 'ประวัติการสั่งบรรจุ', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-confirm' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_order"
          :link="{ name: 'ประวัติการหยิบของ', icon: 'tim-icons icon-app', path: '/order/pick-history' }">
        </sidebar-item>
         <div   v-if="admin_order" style="height: 120px;">
        </div>


          <!-- admin_cargo -->
         <label v-if="admin_cargo" style="color:white; font-size:12px;font-weight: bold;margin: 20px">จัดการสินค้าคงคลัง</label>
        <sidebar-item
          v-if="admin_cargo"
          :link="{ name: 'รับสินค้าเข้าคลัง', icon: 'tim-icons icon-notes', path: '/receive' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_cargo"
          :link="{ name: 'นำสินค้าออกจากคลัง', icon: 'tim-icons icon-notes', path: '/goods-out' }">
        </sidebar-item>
         <label v-if="admin_cargo" style="color:white; font-size:12px;font-weight: bold;margin: 20px">ข้อมูลหลัก</label>
        <sidebar-item
          v-if="admin_cargo"
          :link="{ name: 'สินค้า', icon: 'tim-icons icon-basket-simple' , path: '/product' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_cargo"
          :link="{ name: 'สมาชิก', icon: 'tim-icons icon-single-02', path: '/user' }">
        </sidebar-item>
         <div   v-if="admin_cargo" style="height: 120px;">
        </div>


         <!-- admin_order -->
          <label v-if="admin_director" style="color:white; font-size:12px;font-weight: bold;margin: 20px">จัดการสินค้าคงคลัง</label>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'รับสินค้าเข้าคลัง', icon: 'tim-icons icon-notes', path: '/receive' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_director"
          :link="{ name: 'นำสินค้าออกจากคลัง', icon: 'tim-icons icon-notes', path: '/goods-out' }">
        </sidebar-item>
        <label v-if="admin_director" style="color:white; font-size:12px;font-weight: bold;margin: 20px">กระบวนการบรรจุสินค้า</label>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'รายการรอดำเนินการ', icon: 'tim-icons icon-paper', path: '/order/adminOrderList' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'รายการรอบรรจุ', icon: 'tim-icons icon-app', path: '/picking/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'รายการรอขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'สแกนรายการขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/scan-picking-car/list' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_director"
          :link="{ name: 'รายการขึ้นรถแล้ว', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-complete' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_director"
          :link="{ name: 'อัพเดทการส่งสินค้า', icon: 'tim-icons icon-pencil', path: '/update-delivery' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'ประวัติการสั่งบรรจุ', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-confirm' }">
        </sidebar-item>
         <sidebar-item
          v-if="admin_director"
          :link="{ name: 'ประวัติการหยิบของ', icon: 'tim-icons icon-app', path: '/order/pick-history' }">
        </sidebar-item>
          <label v-if="admin_director" style="color:white; font-size:12px;font-weight: bold;margin: 20px">ข้อมูลหลัก</label>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'สินค้า', icon: 'tim-icons icon-basket-simple' , path: '/product' }">
        </sidebar-item>
        <sidebar-item
          v-if="admin_director"
          :link="{ name: 'สมาชิก', icon: 'tim-icons icon-single-02', path: '/user' }">
        </sidebar-item>
         <div   v-if="admin_director" style="height: 120px;">
        </div>


          <!-- packing -->
         <label v-if="packing" style="color:white; font-size:12px;font-weight: bold;margin: 20px">กระบวนการบรรจุสินค้า</label>
        <sidebar-item
          v-if="packing"
          :link="{ name: 'รายการรอบรรจุ', icon: 'tim-icons icon-app', path: '/picking/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing"
          :link="{ name: 'รายการรอขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing"
          :link="{ name: 'สแกนรายการขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/scan-picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing"
          :link="{ name: 'รายการขึ้นรถแล้ว', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-complete' }">
        </sidebar-item>


        <!-- packing_plus -->
        <!-- 1.รับสินค้าเข้าคลัง   2.อัพเดทการส่งสินค้า  3.กล่อง -->
        <label v-if="packing_plus" style="color:white; font-size:12px;font-weight: bold;margin: 20px">จัดการสินค้าคงคลัง</label>
        <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'รับสินค้าเข้าคลัง', icon: 'tim-icons icon-notes', path: '/receive' }">
        </sidebar-item>
        <label v-if="packing_plus" style="color:white; font-size:12px;font-weight: bold;margin: 20px">กระบวนการบรรจุสินค้า</label>
         <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'รายการรอดำเนินการ', icon: 'tim-icons icon-paper', path: '/order/adminOrderList' }">
        </sidebar-item>
         <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'รายการรอบรรจุ', icon: 'tim-icons icon-app', path: '/picking/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'รายการรอขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'สแกนรายการขึ้นรถ', icon: 'tim-icons icon-delivery-fast', path: '/scan-picking-car/list' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'รายการขึ้นรถแล้ว', icon: 'tim-icons icon-delivery-fast', path: '/picking/list-complete' }">
        </sidebar-item>
        <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'อัพเดทการส่งสินค้า', icon: 'tim-icons icon-pencil', path: '/update-delivery' }">
        </sidebar-item>
         <sidebar-item
          v-if="packing_plus"
          :link="{ name: 'ประวัติการหยิบของ', icon: 'tim-icons icon-app', path: '/order/pick-history' }">
        </sidebar-item>
         <label v-if="packing_plus" style="color:white; font-size:12px;font-weight: bold;margin: 20px">ข้อมูลหลัก</label>
         <sidebar-item
            v-if="packing_plus"
            :link="{ name: 'กล่อง', icon: 'tim-icons icon-app', path: '/box/list'}">
          </sidebar-item>






      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import {mapActions, mapState} from "vuex";


export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackground: 'nRich', //vue|blue|orange|green|red|primary
      MenuAdmin: false,
      MenuUser: false,
      MenuDevelop: false,
      superadmin: false,
      admin_director: false,
      admin_order: false,
      admin_cargo: false,
      packing: false,
      packing_plus: false,
    };
  },
  computed: {
    ...mapState('account', ['user'])
  },
  created() {
    let docClasses = document.body.classList;
    docClasses.add('white-content');
    this.$sidebar.toggleMinimize();
    this.$emit('update:backgroundColor', 'nRich');


  },
  methods: {

    getMe(){
      let user = JSON.parse(localStorage.getItem('users'));
      if(user.role === 'admin'){
        // this.MenuAdmin = true

        if(user.gender === 'superadmin'){
          this.superadmin = true
        }else if(user.gender === 'admin_director'){
          this.admin_director = true
        }else if(user.gender === 'admin_order'){
          this.admin_order = true
        }else if(user.gender === 'admin_cargo'){
          this.admin_cargo = true
        } else if(user.gender === 'packing'){
          this.packing = true
        } else if(user.gender === 'packing_plus'){
          this.packing_plus = true
        }


      }else if(user.role === 'user'){
        this.MenuUser = true
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
    this.getMe()
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>

import config from '../config';
import {authHeader} from '@/helpers';

export const receiveService = {
  save,
  saveOut,
  getReceiveList,
  getBarcodeByRec,
  getBarcodeByRec2,
  getReceiveByID,
  getReceiveOutList,
};
function save(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/receive`, requestOptions).then(handleResponse);
}


function saveOut(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/receive-out`, requestOptions).then(handleResponse);
}


function getReceiveList(){
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/receive/list`, requestOptions).then(handleResponse);
}


function getReceiveOutList(){
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/receive-out/list`, requestOptions).then(handleResponse);
}



function getBarcodeByRec(data){
  console.log(data)
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/receive/barcode/${data.recNo}`, requestOptions).then(handleResponse);
}

function getBarcodeByRec2(data){
  console.log(data)
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://astoria-locker-api.siamsoftwaresolution.com/Mooi_receive/getproductbarode?RecNo=${data.recNo}&BarCode=${data.BarCode}`, requestOptions).then(handleResponse);
}


function getReceiveByID(data){
  console.log(data)
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/receive/${data}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout().then(r => console.log(r));
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

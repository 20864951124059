import config from '../config';
import {authHeader} from '@/helpers';

export const productService = {
  getAdminProduct,
  getMeProduct,
  getProduct,
  getProductByCustomer,
  addProduct,
  updateProduct,
  getProductByID,
  deleteProduct,
  deleteProduct2,
  getLazadaProduct,
};

function addProduct(product) {
  console.log(JSON.stringify(product));
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(product)
  };
  return fetch(`${config.apiUrl}/product`, requestOptions).then(handleResponse);
}
function updateProduct(product) {
  console.log(JSON.stringify(product));
  console.log((product));
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(product)
  };
  return fetch(`https://astoria-locker-api.siamsoftwaresolution.com/Mooi_product/updateproduct/`, requestOptions).then(handleResponse);
}
function getProduct(data) {
  console.log()
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/product`, requestOptions).then(handleResponse);
}

function getLazadaProduct(data) {
  console.log()
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/product-lazada`, requestOptions).then(handleResponse);
}

function deleteProduct(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/product/${data.ID}`, requestOptions).then(handleResponse);
}

function deleteProduct2(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://astoria-locker-api.siamsoftwaresolution.com/Mooi_product/changeproductstatus/`, requestOptions).then(handleResponse);
}



function getProductByCustomer(data) {
  console.log(data)
  console.log("CCCCCCCCC")
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/product/${data}`, requestOptions).then(handleResponse);
}

function getProductByID(data) {
  console.log(data)
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://astoria-locker-api.siamsoftwaresolution.com/Mooi_product/getproductbyid?ID=${data}`, requestOptions).then(handleResponse);
}

function getAdminProduct(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/product/${data.data}`, requestOptions).then(handleResponse);
}



function getMeProduct(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/me/product`, requestOptions).then(handleResponse);
}
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout().then(r => console.log(r));
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

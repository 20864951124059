import {productService} from "@/services/product.service"
import swal from 'sweetalert2';


const state = {
  error: {},
  productList: {},
  productLazadaList:{},
  AddProductStatus: {},
}
const actions = {
  AddProduct({dispatch, commit}, product) {
    productService.addProduct(product.product)
      .then(
        _ => {
          commit('addProductSuccess', product.product)
        },
        _ => {
          //TODO Move To State Watcher
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },
  deleteProduct({dispatch, commit}, product) {
    productService.deleteProduct(product.product)
      .then(
        _ => {
          commit('addProductSuccess', product.product)
        },
        _ => {
          //TODO Move To State Watcher
          swal({
            title: 'ไม่สามารถลบข้อมูลได้',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },
  UpdateProduct({dispatch, commit}, product) {
    console.log("aaaa")
    console.log(product)
    productService.updateProduct(product)
      .then(
        _ => {
          commit('addProductSuccess', product)
        },
        _ => {
          //TODO Move To State Watcher
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },
  deleteProduct2({commit}, data) {
    productService.deleteProduct2(data).then(
      val => {
        commit('ProductSuccess', val)
      },
      error => {
        commit('ProductFailure', error)
      }
    )
  },
  getProductByID({commit}, data) {
    productService.getProductByID(data).then(
      val => {
        commit('ProductSuccess', val)
      },
      error => {
        commit('ProductFailure', error)
      }
    )
  },
  getProductByCustomerID({commit}, data) {
    productService.getProductByCustomer(data).then(
      val => {
        commit('ProductSuccess', val)
      },
      error => {
        commit('ProductFailure', error)
      }
    )
  },
  getAdminProduct({commit}, data) {
    productService.getAdminProduct(data).then(
      val => commit('ProductSuccess', val),
      error => commit('ProductFailure', error)
    )
  },
  getProduct({commit}) {
    productService.getProduct().then(
      val => {
        commit('ProductSuccess', val)
      },
      error => {
        commit('ProductFailure', error)
      }
    )
  },
  getMeProduct({dispatch, commit}, data) {
    productService.getMeProduct(data).then(
      val => {
        commit('ProductSuccess', val)
      },
      error => {
        commit('ProductFailure', error)
      }
    )
  },
  getLazadaProduct({commit}){
    productService.getLazadaProduct().then(
      val => {
        commit("ProductLazadaSuccess",val)
      }
    )
  },
  clearStateStatus({commit}) {
    commit('clearState')
  }
}
const mutations = {
  addProductSuccess(state) {
    state.AddProductStatus = {message: "success"}
  },
  ProductSuccess(state, data) {
    state.productList = {items: data}
  },
  ProductLazadaSuccess(state,data){
    state.productLazadaList = {items: data}
  },
  ProductFailure(state, error) {
    state.orderStatus = {message: "error", err: error};
    state.error = {};
  },
  clearState(state) {
    state.recStatus = {}
  }
}
export const product = {
  namespaced: true,
  state,
  actions,
  mutations
};

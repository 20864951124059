import {userService} from '@/services';
import {router} from '../helpers';
import swal from 'sweetalert2';


const user = JSON.parse(localStorage.getItem('user'));
const state = user ? {status: {loggedIn: true}, user} : {status: {}, user: null};
const actions = {
  login({dispatch, commit}, {username, password}) {
    commit('loginRequest', {username});
    userService.login(username, password)
      .then(
        _ => {
          userService.Me().then(
            user => {
              commit('loginSuccess', user)

              const current = new Date();
              const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
              localStorage.setItem('loginDate', date)

              localStorage.setItem('role', user.role)
              localStorage.setItem('username', user.userName)
              localStorage.setItem('email', user.email)
              localStorage.setItem('firstName', user.firstName)
              localStorage.setItem('lastName', user.lastName)

              if(user.ID.length == 3){
                localStorage.setItem('userID', 'C'+user.ID)
              }else {
                localStorage.setItem('userID', 'C0'+user.ID)
              }

              router.push('/').catch(err => {
                console.log(err)
              })
            },
            error => {
              commit('loginFailure', error);
              swal({
                title: 'ไม่สามารถเข้าสู่ระบบได้',
                text: `กรุณาตรวจจสอบชื่อผู้ใช้งานและรหัสผ่าน`,
                type: 'error',
              });
            }
          )
        },
        _ => {
          swal({
            title: 'ไม่สามารถเข้าสู่ระบบได้',
            text: `กรุณาตรวจจสอบชื่อผู้ใช้งานและรหัสผ่าน`,
            type: 'error',
          });
        }
      );
  },
  me({dispatch, commit}) {
    userService.Me().then(
      _ => {
        commit('getMeSuccess')
        router.push('/').catch(_ => {})
      },
      error => {
        commit('loginFailure', error);
        dispatch('alert/error', error, {root: true});
      }
    )
  },
  logout({commit}) {
    userService.logout().then(
      _ => {
        commit('logout');
        router.push('/login').catch(err => {
          localStorage.setItem('loginDate', "")
          console.log(err)
        })
      }, error => {
        localStorage.setItem('loginDate', "")
        commit('loginFailure', error);
      }
    )

  },
  register({dispatch, commit}, user) {
    commit('registerRequest', user);

    userService.register(user)
      .then(
        user => {
          commit('registerSuccess', user);
          router.push('/login').then(r => {
          });
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Registration successful', {root: true});
          })
        },
        error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, {root: true});
        }
      );
  }
};

const mutations = {
  loginRequest(state, user) {
    state.status = {loggingIn: true};
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = {loggedIn: true};
    state.user = user;
  },
  getMeSuccess(state, user) {
    state.user = user
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    localStorage.setItem('loginDate', "")
    state.status = {};
    state.user = null;
  },
  registerRequest(state, user) {
    state.status = {registering: true};
  },
  registerSuccess(state, user) {
    state.status = {};
  },
  registerFailure(state, error) {
    state.status = {};
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};

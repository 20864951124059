import {userService} from '@/services';
import swal from 'sweetalert2';

const state = {
  all: {},
  status: "",
  statusItem: "",
  user: {},
  barcode: {},
  itemData: {},
  stockData: {},
  userData: {},
  AddUserStatus: {},
  TopUpStatus:{},
  transaction:{},
  transaction2:{},
  transaction3:{},
  transaction4:{},
  transaction5:{},
  transaction6:{},
  transaction7:{},
};



const actions = {
  Me({commit}) {
    userService.Me().then(
      user => {
        commit('getUserSuccess', user)
      },
      error => {
        commit('getUserFailure', error)
      }
    )
  },
  AddUser({dispatch, commit}, user) {
    userService.register(user)
      .then(
        user => {
          commit('addUserSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },

  AddUser2({dispatch, commit}, data) {
    userService.register2(data)
      .then(
        user => {
          commit('addUserSuccess', data)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },

  AddUserStaff({dispatch, commit}, user) {
    userService.registerStaff(user)
      .then(
        user => {
          commit('addUserSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },
  UpdateUser({dispatch, commit}, user) {
    userService.update(user)
      .then(
        user => {
          commit('updateSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },

  updatecustomer({dispatch, commit}, user) {
    userService.updatecustomer(user)
      .then(
        user => {
          commit('updateSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },



  UpdatePassword({dispatch, commit}, user) {
    userService.updatePassword(user)
      .then(
        user => {
          commit('updateSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },


  UpdateUserStaff({dispatch, commit}, user) {
    userService.updateStaff(user)
      .then(
        user => {
          commit('updateSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },





  UpdateMe({dispatch, commit}, user) {
    userService.editMeUser(user)
      .then(
        user => {
          commit('addUserSuccess', user)
        },
        error => {
          swal({
            title: 'ไม่สามารถบันทึก',
            text: `กรุณาตรวจจสอบข้อมูล`,
            type: 'error',
          });
        }
      );
  },
  getAll({commit}) {
    commit('getAllRequest');
    userService.getAll()
      .then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      );
  },

  getcustomers({commit}) {
    commit('getAllRequest');
    userService.getcustomers()
      .then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      );
  },


  getAllStaff({commit}) {
    commit('getAllRequest');
    userService.getAllStaff()
      .then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      );
  },



  getAll2({commit}) {
    commit('getAllRequest');
    userService.getAll()
      .then(
        users => commit('getAllSuccess2', users),
        error => commit('getAllFailure', error)
      );
  },
  getUserDetail({dispatch, commit}, id) {
    userService.getById(id).then(
      users => commit('getUserSuccess', users),
      error => commit('getAllFailure', error)
    )
  },
  getUserBarcode({dispatch, commit}, id) {
    userService.getUserCode(id).then(
      data => commit('getUserCodeSuccess', data),
      error => commit('getAllFailure', error)
    )
  },
  getItemCustomerByCode({dispatch, commit}, data) {
    userService.getItemCustomerByCode(data).then(
      data => commit('getItemCodeSuccess', data),
      error => commit('getItemCodeFail', error)
    )
  },
  getItemByCode({dispatch, commit}, data) {
    userService.getItemByCode(data).then(
      data => commit('getItemCodeSuccess', data),
      error => commit('getItemCodeFail', error)
    )
  },
  getUserByCode({dispatch, commit}, code) {
    userService.getUserByID(code).then(
      users => commit('getUserSuccess', users),
      error => commit('getAllFailure', error)
    )
  },
  getcustomerbyid({dispatch,commit},ID){
    userService.getcustomerbyid(ID).then(
      users => commit('getUserSuccess', users),
      error => commit('getAllFailure', error)
    )
  },
  getUserByID({dispatch,commit},ID){
    userService.getUserByID(ID).then(
      users => commit('getUserSuccess', users),
      error => commit('getAllFailure', error)
    )
  },
  getLastItemCode({dispatch, commit}, id) {
    userService.getLastItemCode(id).then(
      data => commit('getItemCodeSuccess', data),
      error => commit('getAllFailure', error)
    )
  },
  getLastItem({dispatch, commit}, data) {
    userService.getLastItem(data).then(
      data => commit('getItemCodeSuccess', data),
      error => commit('getAllFailure', error)
    )
  },

  
  getlocations({dispatch, commit}) {
    userService.getlocations().then(
      data => commit('getStockSuccess', data),
      error => commit('getAllFailure', error)
    )
  },
  getMeStock({dispatch, commit}) {
    userService.getMeStock().then(
      data => commit('getStockSuccess', data),
      error => commit('getAllFailure', error)
    )
  },
  getMeStockTable({dispatch, commit}) {
    userService.getMeStock().then(
      data => commit('getStockSuccessTable', data),
      error => commit('getAllFailure', error)
    )
  },
  getMeProduct({dispatch,commit}){
    userService.getMeProduct().then(
      data => commit('getStockSuccessTable',data),
      error => commit('getAllFailure', error)
    )
  },

  getMeProductDetail({dispatch,commit} , {id}){
    userService.getMeProductDetail(id).then(
      data => commit('getStockSuccess',data),
      error => commit('getAllFailure', error)
    )
  },
  adminApprove({dispatch, commit}, {data}) {
    let payload = {
      "approve_uid": data.ID,
      "dimension_height_confirm": data.DimensionH,
      "dimension_width_confirm": data.DimensionW,
      "barcode_prefix": "CUS"

    }
    userService.Approve(payload).then(
      users => commit('approveSuccess', users),
      error => commit('approveFail', error)
    )
  },
  delete({commit}, id) {
    commit('deleteRequest', id);

    userService._delete(id)
      .then(
        _ => commit('deleteSuccess', id),
        error => commit('deleteFailure', {id, error: error.toString()})
      );
  },
  deleteUser({commit}, id) {
    commit('deleteRequest', id);
    userService.deleteUser(id)
      .then(
        _ => commit('deleteSuccess', id),
        error => commit('deleteFailure', {id, error: error.toString()})
      );
  },
  TopUpMoney({dispatch,commit},data) {
    userService.TopUpMoney(data).then(
      val => {
        dispatch('getAll')
        commit("TopUpSuccess",val)
      },
      err => commit("TopUpFailure",err)
    )
  },
  getAdminTransaction({commit} , data) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }


    userService.TransactionAdmin(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },
  getTransactionSummary({commit} , data) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getTransactionSummary(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getTransactionSummaryUser({commit} , data) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getTransactionSummaryUser(day ,data.Mount , data.Year  , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },




  getTransactionSummaryAdmin({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getTransactionSummaryAdmin(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportZipCodeAdmin({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportZipCodeAdmin(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportBoxAdmin({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportBoxAdmin(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },




  getReportZipCode({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportZipCode(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },



  getReportZipCodeAdminTop10({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportZipCodeAdminTop10(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportZipCodeTop10({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportZipCodeTop10(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },


  getReportProductAdmin({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportProductAdmin(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportProduct({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportProduct(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },


  getShopAdminTop10({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }

    userService.getShopAdminTop10(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess7",val),
      err => commit("getTransactionFailure",err)
    )
  },


  getReportProductAdminTop10({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }

    userService.getReportProductAdminTop10(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess2",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportProductTop10({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportProductTop10(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess2",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportMonthlyOrderAdmin({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportMonthlyOrderAdmin(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess3",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportMonthlyOrder({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportMonthlyOrder(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess3",val),
      err => commit("getTransactionFailure",err)
    )
  },


  getReportMonthlyOrderExport({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportMonthlyOrderExport(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess6",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getReportBoxAdminExprot({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportBoxAdminExprot(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess6",val),
      err => commit("getTransactionFailure",err)
    )
  },





  getReportSummaryTransactionExport({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportSummaryTransactionExport(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess6",val),
      err => commit("getTransactionFailure",err)
    )
  },


  getReportMonthlyProductExport({commit} , data ) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.getReportMonthlyProductExport(day ,data.Mount , data.Year , data.UserID).then(
      val =>commit("getTransactionSuccess6",val),
      err => commit("getTransactionFailure",err)
    )
  },






  getDashboard({commit}) {
    userService.getDashboard().then(
      val =>commit("getTransactionSuccess4",val),
      err => commit("getTransactionFailure",err)
    )
  },

  getWallets({commit}) {
    userService.getWallets().then(
      val =>commit("getTransactionSuccess5",val),
      err => commit("getTransactionFailure",err)
    )
  },



  getMeTransaction({commit} , data) {
    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }
    userService.Transaction(day ,data.Mount , data.Year).then(
      val =>commit("getTransactionSuccess",val),
      err => commit("getTransactionFailure",err)
    )
  },
  clearState({commit}) {
    commit('clearState')
  }
};

const mutations = {
  getTransactionSuccess(state,data){
    state.transaction = {message:"success",items:data}
  },
  getTransactionSuccess2(state,data){
    state.transaction2 = {message:"success",items:data}
  },
  getTransactionSuccess3(state,data){
    state.transaction3 = {message:"success",items:data}
  },
  getTransactionSuccess4(state,data){
    state.transaction4 = {message:"success",items:data}
  },

  getTransactionSuccess5(state,data){
    state.transaction5 = {message:"success",items:data}
  },
  getTransactionSuccess6(state,data){
    state.transaction6 = {message:"success",items:data}
  },
  getTransactionSuccess7(state,data){
    state.transaction7 = {message:"success",items:data}
  },

  getTransactionFailure(state,err){
    state.transaction = {message:"fail",items:err}
  },
  TopUpSuccess(state){
    state.TopUpStatus = {message: "success"}
  },
  TopUpFailure(state){
    state.TopUpStatus = {message: "fail"}
  },
  addUserSuccess(state) {
    state.AddUserStatus = {message: "success"}
  },
  addUserFail(state) {
    state.AddUserStatus = {message: "fail", error: error}
  },
  getAllRequest(state) {
    state.all = {loading: true};
  },
  getAllSuccess(state, users) {
    state.userData = {items: users};
  },
  getAllSuccess2(state, users) {
    state.userData = {items: users};
  },
  approveSuccess(state, users) {
    state.user = users
    state.status = {message: "success"}
  },
  approveFail(state, error) {
    state.status = {message: "fail", error: error}
  },
  getUserSuccess(state, users) {
    state.user = users
  },
  getUserFailure(state, error) {
    state.all = {error};
    state.status = {message: "error"}
  },
  getUserCodeSuccess(state, data) {
    state.barcode = data
  },
  getItemCodeSuccess(state, data) {
    state.itemData = data
  },
  getStockSuccess(state, data) {
    state.stockData = data
  },

  getStockSuccessTable(state, data) {
    state.stockData = {items: data}
  },
  getAllFailure(state, error) {
    state.all = {error};
    state.status = {message: "error"}
  },
  getItemCodeFail(state, error) {
    state.all = {error}
    state.statusItem = {message: "error"}
  },
  deleteRequest(state, id) {
    state.all.items = state.all.items.map(user =>
      user.id === id
        ? {...user, deleting: true}
        : user
    )
  },
  updateSuccess(state,data){
    state.AddUserStatus = {message: "success",data:data}
  },
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter(user => user.id !== id)
  },
  deleteFailure(state, {id, error}) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        const {deleting, ...userCopy} = user;
        // return copy of user with 'deleteError:[error]' property
        return {...userCopy, deleteError: error};
      }

      return user;
    })
  },
  clearState(state) {
    state.status = {message: ""}
  }
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations
};


import Vue from 'vue';
import Vuex from 'vuex';

import {account} from '@/stores/account.module';
import {users} from '@/stores/users.module';
import {alert} from '@/stores/alerts.module'
import {receive} from '@/stores/receive.module';
import {orders} from '@/stores/order.module'
import {product} from "@/stores/product.module";

Vue.use(Vuex);


export const store = new Vuex.Store({
  modules: {
    account,
    users,
    alert,
    receive,
    orders,
    product,
  }
});

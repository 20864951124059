import {receiveService} from "@/services/receive.service";

const state = {
  error: {},
  recStatus: {},
  recList: {},
  BarcodeList:{},
  itemList:{}
}
const actions = {
  getAll({commit}) {
    receiveService.getReceiveList().then(
      data => commit('getReceiveListSuccess', data),
      error => commit('ReceiveFailure', error)
    )
  },
  getAllOut({commit}) {
    receiveService.getReceiveOutList().then(
      data => commit('getReceiveListSuccess', data),
      error => commit('ReceiveFailure', error)
    )
  },
  SaveReceiveData({dispatch, commit}, data) {
    receiveService.save(data).then(
      data => commit('ReceiveSuccess', data),
      error => commit('ReceiveFailure', error)
    )
  },

  SaveReceiveOutData({dispatch, commit}, data) {
    receiveService.saveOut(data).then(
      data => commit('ReceiveSuccess', data),
      error => commit('ReceiveFailure', error)
    )
  },



  getBarcodeByRecNo({dispatch,commit},data){
    receiveService.getBarcodeByRec(data).then(
      data =>commit('getBarcodeListSuccess',data),
      error => commit('ReceiveFailure', error)
    )
  },
  getBarcodeByRecNo2({dispatch,commit},data){
    receiveService.getBarcodeByRec2(data).then(
      data =>commit('getBarcodeListSuccess',data),
      error => commit('ReceiveFailure', error)
    )
  },


  getReceiveByID({dispatch,commit},data){
    receiveService.getReceiveByID(data).then(
      data =>commit('getReceiveSuccess',data),
      error => commit('ReceiveFailure', error)
    )
  },


  clearStateStatus({commit}) {f
    commit('clearState')
  }
}
const mutations = {
  getReceiveListSuccess(state, data) {
    state.recList = {items: data}
  },


  getReceiveSuccess(state, data) {
    state.itemList = {items: data}
  },



  getBarcodeListSuccess(state, data) {
    state.BarcodeList = {items: data}
  },
  ReceiveSuccess(state, data) {
    state.recStatus = {message: "success"};
  },
  ReceiveFailure(state, error) {
    state.recStatus = {message: "error", err: error};
    state.error = {};
  },
  clearState(state) {
    state.recStatus = {}
  }
}
export const receive = {
  namespaced: true,
  state,
  actions,
  mutations
};

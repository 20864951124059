import {orderService} from "@/services/order.service"
import swal from 'sweetalert2';


const state = {
  error: {},
  saveOrderStatus:{},
  orderStatus: {},
  orderStatusScan: {},
  orderDetail: {},
  orderHead: {},
  orderTransactionItem: {},
  orderLocation: {},
  orderList: {},
  orderListAdmin: {},
  orderListAdmin2: {},
  orderListAdmin3: {},
  pickingList: {},
  boxList: {},
  zipList: {},
  orderTransaction :{},
  uploadTrackList :{},
}
const actions = {


  uploadFileOrder({commit}, data) {
    orderService.uploadFileOrder(data).then(
      data => commit('uploadTrackSS', data),
      error => commit('OrderFailure', error)
    )
  },


  uploadTrack({commit}, data) {
    orderService.uploadTrack(data).then(
      data => commit('uploadTrackSS', data),
      error => commit('OrderFailure', error)
    )
  },

  confirmPacked({commit}, data) {
    orderService.confirmPackedOrder(data).then(
      data => commit('PickingConfirmSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  confirmPicking({commit}, data) {
    orderService.confirmPickingOrder(data).then(
      data => commit('PickingConfirmSuccess', data),
      error => commit('OrderFailureScan', error)
    )
  },
  confirmPackingOrder({commit}, data) {
    orderService.confirmPackingOrder(data).then(
      data => commit('PickingConfirmSuccess2', data),
      error => commit('OrderFailureScan', error)
    )
  },

  confirmPackingOrder2({commit}, data) {
    orderService.confirmPackingOrder2(data).then(
      data => commit('PickingConfirmSuccess2', data),
      error => commit('OrderFailureScan', error)
    )
  },


  pickingItem({dispatch, commit}, data) {
    orderService.PickingItem(data).then(
      val => {
        dispatch('getOrderDetail', data.OrderNo)
        commit('OrderSuccess', val)
      },
      error => {
        commit('OrderFailure', error)
      }
    )
  },
  pickingOrder({commit}, data) {
    orderService.getPickingList(data).then(
      data => commit('PickingListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  getlocation({commit}, data) {
    orderService.getlocation(data).then(
      data => commit('PickingListSuccessLocation', data),
      error => commit('OrderFailure', error)
    )
  },

  getOrderDetail({commit}, data) {
    orderService.getOrderDetail(data).then(
      data => commit('OrderInfoSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  MeOrderDetail({commit}, data) {
    orderService.MeOrderDetail(data).then(
      data => commit('OrderInfoSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  deleteOrder({commit}, data) {
    orderService.deleteOrder(data).then(
      data => commit('OrderInfoSuccess2', data),
      error => commit('OrderFailure2', error)
    )
  },

  deleteOrder2({commit}, data) {
    orderService.deleteOrder2(data).then(
      data => commit('OrderInfoSuccess2', data),
      error => commit('OrderFailure2', error)
    )
  },


  getOrderTransaction({commit}, data) {
    orderService.getOrderTransaction(data).then(
      data => commit('OrderTransaction', data),
      error => commit('OrderFailure2', error)
    )
  },





  adminOrders({commit}) {
    orderService.getAdminOrderList().then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },



  getZipCode({commit}) {
    orderService.getZipCode().then(
      data => commit('ZipCodeSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  getBox({commit}) {
    orderService.getBox().then(
      data => commit('BoxListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  getBoxAll({commit}) {
    orderService.getBoxAll().then(
      data => commit('BoxListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },



  getBoxById({commit} , data ) {
    orderService.getBoxById(data.id).then(
      val =>commit("BoxListSuccess",val),
      err => commit("OrderFailure",err)
    )
  },



  updateBox({commit} , data ) {
    orderService.updateBox(data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  addboxamount({commit} , data ) {
    orderService.addboxamount(data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailure', error)
    )
  },


  addBox({commit} , data ) {
    orderService.addBox(data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  updateBoxStatus({commit} , data ) {
    orderService.updateBoxStatus(data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailure', error)
    )
  },




  adminPickingOrders({commit}) {
    orderService.getAdminPickingOrderList().then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  adminPackedOrders({commit}) {
    orderService.getAdminPackedOrderList().then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  adminPickingConfirmOrders({commit} , data) {

    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }


    orderService.getAdminPickingConfirmOrderList(day , data.Mount , data.Year , data.UserID).then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  adminPickingConfirmOrders2({commit} , data) {

    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }

    orderService.getAdminPickingConfirmOrderList2(day , data.Mount , data.Year , data.UserID).then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  getAll({commit} , data) {

    var day = ""
    if (data.Day != undefined) {
      day = data.Day
    }

    orderService.getOrderList(day , data.Mount , data.Year).then(
      data => commit('getOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },


  getOrderHistoryList({commit}) {
    orderService.getOrderHistoryList().then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },

  getOrderHistoryListDetail({dispatch, commit}, data) {
    orderService.getOrderHistoryListDetail(data).then(
      data => commit('AdminOrderListSuccess', data),
      error => commit('OrderFailure', error)
    )
  },


  getOrderHistoryListDetail2({dispatch, commit}, data) {
    orderService.getOrderHistoryListDetail2(data).then(
      data => commit('AdminOrderListSuccess2', data),
      error => commit('OrderFailure', error)
    )
  },

  getOrderHistoryListDetail3({dispatch, commit}, data) {
    orderService.getOrderHistoryListDetail3(data).then(
      data => commit('AdminOrderListSuccess3', data),
      error => commit('OrderFailure', error)
    )
  },


  SaveOrderData({dispatch, commit}, data) {
    orderService.save(data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailure', error)
    )
  },
  uploadProductImage({commit}, data) {
    orderService.UploadProductImage(data.data).then(
      data => commit('OrderSuccess', data),
      error => commit('OrderFailureImg', error)
    )
  },
  clearStateStatus({commit}) {
    commit('clearState')
  }
}
const mutations = {
  PickingListSuccess(state, data) {
    state.pickingList = {data}
  },
  PickingListSuccessLocation(state, data) {
    state.orderLocation = {data}
  },
  getOrderListSuccess(state, data) {
    state.orderList = {items: data}
  },
  AdminOrderListSuccess(state, data) {
    state.orderListAdmin = {items: data}
  },
  AdminOrderListSuccess2(state, data) {
    state.orderListAdmin2 = {items: data}
  },
  AdminOrderListSuccess3(state, data) {
    state.orderListAdmin3 = {items: data}
  },


  BoxListSuccess(state, data) {
    state.boxList = {items:data}
  },

  ZipCodeSuccess(state, data) {
    state.zipList = {items:data}
  },

 
 


  OrderInfoSuccess(state, data) {
    state.orderDetail = {items: data.Detail}
    state.orderHead = {item: data.Head}
    state.orderTransactionItem = {item: data.Transaction}
  },
  OrderInfoSuccess2(state, data) {
    state.orderHead = {item: data.Head}
  },

  uploadTrackSS(state, data) {
    state.uploadTrackList = {item: data}
  },

  OrderTransaction(state, data) {
    state.orderTransaction = {item: data}
  },


  OrderFailureImg(state, data) {
    swal({
      title: 'ไม่สามารถแก้ไขได้',
      text: `กรุณาตรวจสอบข้อมูล`,
      type: 'error',
    });
  },


  PickingConfirmSuccess(state,data) {
    state.saveOrderStatus = {message: "success"}
  },

  PickingConfirmSuccess2(state,data) {
    state.saveOrderStatus = {data: data}
  },





  OrderSuccess(state, data) {
    state.orderStatus = {message: "success"};
  },
  OrderFailure(state, error) {
    state.orderStatus = {message: "error", err: error};
    state.error = {};
  },
  OrderFailureScan(state, error) {
    state.error = {};
    swal({
      title: 'ไม่สามารถทำรายการได้',
      text: `เลข Order No นี้ไม่อยู่ในสถานะรอขึ้นรถ กรุณาตรวจสอบเลข Order No`,
      type: 'error',
    });

  },
  OrderFailure2(state, error) {
    state.orderStatus = {message: "error", err: error};
    state.error = {};
  },
  clearState(state) {
    state.recStatus = {}
  }
}
export const orders = {
  namespaced: true,
  state,
  actions,
  mutations
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import HtmlToPaper from "./plugins/html-print-plugin";
// import { MdIcon } from 'vue-material/dist/components'


import App from './App.vue';
import { store } from './stores';
// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
import('./config')

Vue.use(DashboardPlugin)
Vue.use(HtmlToPaper)
Vue.use(VueRouter)
Vue.use(RouterPrefetch)
// Vue.use(MdIcon)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  i18n
});

<template>
  <div >
    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">

        <router-view></router-view>



        <footer class="footer">
          <div class="container-fluid">

          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import { BaseNav , TabPane, Tabs, Collapse, CollapseItem } from 'src/components';


export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
      TabPane,
    Tabs,
    Collapse,
    CollapseItem
  },
  props: {
    backgroundColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 0,
      pageTransitionDuration: 0,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
   created() {
    //  window.location.reload()
    console.log(localStorage.getItem('reload'))

    if(localStorage.getItem('reload') === null) {
       localStorage.setItem('reload', 'reload')
       window.location.reload()
    }

  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    },
    setPageClassNRich() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase()+'-nRich';
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClassNRich();
  },
  watch: {
    $route() {
      this.setPageClassNRich();
    }
  }
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>

<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group" @click="searchModalVisible = true"></div>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title">
          <div class="photo"><img src="img/NRF_LOGO.jpg" /></div>
          <span> {{username}}</span>  &nbsp; &nbsp; &nbsp;
          <b class="caret d-none d-lg-block d-xl-block"></b>
        </template>
        <!-- <li class="nav-link"  v-if="MenuUser == false">
          <a href="#/change-password" class="nav-item dropdown-item">เปลี่ยนรหัสผ่าน</a>
        </li> -->
         <li class="nav-link"  v-if="MenuUser">
          <a href="#/edit-profile" class="nav-item dropdown-item">แก้ไข้อมูลส่วนตัว</a>
        </li>
        <!-- <div class="dropdown-divider"></div> -->
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" v-on:click="signOut">ออกจากระบบ</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import {mapActions} from "vuex";

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      MenuAdmin: false,
      MenuUser: false,
      MenuDevelop: false,
      superadmin: false,
      admin_director: false,
      admin_order: false,
      admin_cargo: false,
      packing: false,
      packing_plus: false,
      username: '',
      email: '',
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },

  methods: {
    ...mapActions('account', ['logout']),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
     getMe(){
      let user = JSON.parse(localStorage.getItem('users'));
      if(user.role === 'admin'){
        // this.MenuAdmin = true

        if(user.gender === 'superadmin'){
          this.superadmin = true
        }else if(user.gender === 'admin_director'){
          this.admin_director = true
        }else if(user.gender === 'admin_order'){
          this.admin_order = true
        }else if(user.gender === 'admin_cargo'){
          this.admin_cargo = true
        } else if(user.gender === 'packing'){
          this.packing = true
        } else if(user.gender === 'packing_plus'){
          this.packing_plus = true
        }


      }else if(user.role === 'user'){
        this.MenuUser = true
      }
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    signOut() {
      this.logout()
    }
  },
   mounted() {
    this.username =  localStorage.getItem('username');
    this.email =  localStorage.getItem('email');
     this.getMe()
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>

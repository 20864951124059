export function authHeader() {
  // return authorization header with jwt token
  let auth = JSON.parse(localStorage.getItem('auth'));

  if (auth && auth.token) {

    return {
      'Authorization': auth.token,
      'Content-Type': 'application/json',
      'Cookie': 'act=' + auth.token
    };
  } else {
    return {};
  }
}

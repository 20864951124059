import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=yes'
  ]
}
export default {
  install(Vue) {
    Vue.use(VueHtmlToPaper,options);
  }
};


